import React from 'react'
import Lottie from 'react-lottie';
import * as animationData from '../Animation.json'

const  LottieControl = () =>  {

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  }

    return (
      <div>
        <Lottie
          options={defaultOptions}
          height={163}
          width={163}
        />
    </div>
  )
}

export default LottieControl