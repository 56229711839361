import ArrowTop from './../images/contactImage.png';

const ContactUsBlock = () => {

  return (
    <div className="mt-9 space-y-10 w-full mt-[80px]">
      <div className="grid grid-cols-5 gap-x-[100px] items-center">
        <div className="col-span-2 min-w-[550px]">
          <img className="w-full" src={ArrowTop} alt="png" />
        </div>
        <form className="col-span-3">
          <p className="text-[#252B33] text-5xl font-bold">Свяжитесь с нами!</p>
          <div className="space-y-6 flex flex-col mt-[54px]">
            <input className="border border-1 border-gray-400 rounded-full h-[55px] max-w-[650px] px-[20px]" placeholder="Имя"/>
            <input className="border border-1 border-gray-400 rounded-full h-[55px] max-w-[650px] px-[20px]" placeholder="Эл. Почта"/>
            <textarea className="border border-1 border-gray-400 rounded-[30px] max-w-[650px] px-[20px] flex items-center justify-center min-h-[130px] py-[20px]" rows="4"  cols="50"  placeholder="Сообщение" />
            <div className="flex justify-center max-w-[650px]">
             <button className="mt-3.5 rounded-[30px] h-[52px] max-w-[165px] bg-gradient-to-r from-[#DE4333] to-[#FDA110] text-white px-10 font-bold">Отправить</button>
            </div>
          </div>
        </form>
        </div>
    </div>
  );
};

export default ContactUsBlock;
