import Logo from '../images/logo.png';
import Web from '../images/web.svg';
import Watsapp from '../images/watsapp.svg';
import Instagram from '../images/instagram.svg';
import Phone from '../images/phone.svg';
import Email from '../images/email.svg';
import { Link } from 'react-router-dom';

const menu = [
  { title: 'Главный' },
  { title: 'О нас' },
  { title: 'Условия' },
  { title: 'Возможности' },
  { title: 'FAQ' },
  { title: 'Обратная связь' }
];

const iconsBlock = [
  { title: 'watsapp', icon: Web },
  { title: 'instagram', icon: Watsapp },
  { title: 'email', icon: Instagram },
  { title: 'phone', icon: Phone },
  { title: 'web', icon: Email }
];

const Header = () => {
  return (
    <div className='mt-10 flex justify-center gap-x-[70px] items-center px-36'>
      <div>
        <Link to="/">
          <img src={Logo} alt="png" />
        </Link>
      </div>
      <div className='flex gap-x-[25px]'>
        {menu.map((item, index) => {
          return (
            <div key={index}>
              <p className="text-[#333F51]">{item.title}</p>
            </div>
          );
        })}
      </div>
      <div className='flex gap-x-[14px]'>
        {iconsBlock.map((item, index) => {
          return (
            <div key={index}>
              <img src={item.icon} alt="svg" />
            </div>
          );
        })}
        <div className="flex justify-center items-center border rounded-[30px] h-[41px] bg-gradient-to-r from-[#DE4333] to-[#FDA110]">
          <button className="m-px border rounded-[30px] h-[35px] px-10 bg-white">Я повар!</button>
        </div>
      </div>
    </div>
  );
};

export default Header;
