import Image1 from './../images/new-1.jpg'
import image2 from './../images/new-2.jpg'
import image3 from './../images/new-4.png'
import image4 from './../images/new-3.png'
import Process from './../images/proces.png'
import CoverHow from "../images/coverHow.png";

const data = [
  {icon: Image1, title: 'Заполнение заявки', subtitle: 'Перейдите на сайт DomFood и заполните форму регистрации, указав основную информацию о себе и своем кулинарном бизнесе.'},
  {icon: image2, title: 'Создание меню', subtitle: 'Разработайте и загрузите ваше меню на платформу. Мы берем на себя фотоссесию Ваших блюд и предоставляем Вам эту услугу бесплатно. Добавьте описания блюд, фотографии и цены.'}
]

const dataTwo = [
  {icon: image3, title: 'Загрузка документов', subtitle: 'Предоставьте необходимые документы для подтверждения вашей квалификации и соблюдения санитарных норм.'},
  {icon: image4, title: 'Начало приема заказов', subtitle: 'После одобрения вашей заявки, вы можете начинать принимать заказы и готовить вкусные блюда для наших клиентов. Наши курьеры доставят до клиентра'}
]

const steps = [
  1,2,3,4
]

const gridItemStyle = {
  backgroundImage: `url(${CoverHow})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  position: 'relative'
}

const HowItsWork = () => {
  return (
    <div className="mt-40">
        <div className="flex justify-center">
          <div className="w-[330px] text-nowrap">
            <p className="gradient-text text-3xl font-bold">Как это работает?</p>
          </div>
        </div>
        <div className="px-[95px] bg-[#ffffff] mt-20">
         <div style={gridItemStyle} className="flex px-[195px] justify-around py-[115px]">
          <div className="space-y-10">
            {data.map((item,index) => {
              return (
                <div className="shadow-xl w-[384px] flex items-center flex-col bg-white max-h-[403px] min-h-[403px] rounded-2xl py-[20px] px-[32px]" key={index}>
                  <img className="max-w-[120px] max-h-[120px]" src={item.icon} alt="svg" />
                  <p className='gradient-text text-2xl mt-[10px] font-bold'>{item.title}</p>
                  <p className="text-lg	text-[#333333] mt-[10px] text-center">{item.subtitle}</p>
                </div>
              )
            })}
          </div>
           <img className="absolute" src={Process} alt="png" />
          <div className="space-y-10 mt-[210px]">
            {dataTwo.map((item,index) => {
              return (
                <div className="shadow-xl w-[384px] flex items-center flex-col bg-white max-h-[403px] min-h-[403px] rounded-2xl py-[20px] px-[32px]" key={index}>
                  <img className="max-w-[120px] max-h-[120px]" src={item.icon} alt="svg" />
                  <p className='gradient-text text-2xl mt-[10px] font-bold'>{item.title}</p>
                  <p className="text-lg	text-[#333333] mt-[10px] text-center">{item.subtitle}</p>
                </div>
              )
            })}
          </div>
        </div>
        </div>
      </div>
  )
}

export default HowItsWork

