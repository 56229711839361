import {Link, NavLink} from "react-router-dom";
import Logo from './../images/logo.png';
import Web from '../images/web.svg';
import Watsapp from '../images/watsapp.svg';
import Instagram from '../images/instagram.svg';
import Phone from '../images/phone.svg';
import Email from '../images/email.svg';

const data = [
  {title: 'Правила и условия', href: 'terms_and_conditions'},
  {title: 'Политика конфиденциальности', href: 'privacy_policy'},
  {title: 'Условия обслуживания', href: 'terms_of_service'},
  {title: 'Check Account', href: 'deleted_account'},
]

const iconsBlock = [
  { title: 'watsapp', icon: Web },
  { title: 'instagram', icon: Watsapp },
  { title: 'email', icon: Instagram },
  { title: 'phone', icon: Phone },
  { title: 'web', icon: Email }
];

const Footer = () => {
  return (
    <div className="bg-[#f7f8f9] px-[180px] py-[20px] flex items-center gap-x-[40px]">
      <div>
        <Link to="/">
          <img alt="svg" src={Logo} />
        </Link>
      </div>
      <nav>
        <ul className="flex gap-x-4">
          {data.map(({ href, title }) => (
            <li className='m-2' key={title}>
              <NavLink to={href} >
                <p className={'text-black'}>{title}</p>
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
      <div className="flex gap-x-4 items-center">
        {iconsBlock.map((item, index) => {
          return (
            <div key={index}>
              <img src={item.icon} alt="svg" />
            </div>
          );
        })}
      </div>
    </div>
  )
}

export default Footer
