import FoodImage from '../images/food.png'
import Click from '../images/click.svg'

const Call = () => {
  return (
    <div className="flex justify-between">
      <img src={FoodImage} alt="svg" />
      <div className="flex gap-x-[13px] items-center">
        <img className="w-[20px]" src={Click} alt="svg" />
        <p>Scroll Down</p>
      </div>
      <div></div>
      {/*<img src="" alt="svg"/>*/}
    </div>
  )
}

export default Call