import {useEffect} from "react";

const PrivacyPolicy = () => {

  useEffect(() => {
    window.scrollTo(10, 10)
  }, [])

  return (
    <div className="px-56 my-20">
      <h1 className="text-center font-bold text-3xl">Privacy & Security</h1>
      <h3 className="font-bold text-2xl mt-4">PRIVACY NOTICE FOR DOM FOOD LLC</h3>
      <h3 className="font-bold text-xl mt-4">Last updated: September 04, 2024</h3>
      <p className="mt-14">This privacy notice for DOM FOOD LLC ("Company," "we," "us," or "our") describes how and why we collect, store, use, and/or share ("process") your information when you use our services ("Services"), including when you:
        • Visit our website at https://www.dom-food.com/, or any other website of ours that links to this privacy notice.
        • Download and use our mobile application (DOM FOOD), or any other application of ours that links to this privacy notice.
        • Engage with us in other related ways, including sales, marketing, or events.</p>
      <p>Questions or Concerns? Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have questions or concerns, please contact us at info@domfood.com.</p>
      <h3 className="font-bold text-2xl mt-4">SUMMARY OF KEY POINTS</h3>
<p>This summary highlights key points from our privacy notice. For more detailed information on any of these topics, please click the links provided or use the table of contents below. You can also click here to go directly to our table of contents.</p>
      <p>    • What personal information do we process?: We process personal information based on your interactions with DOM FOOD LLC and the Services, the choices you make, and the products and features you use. Click here to learn more.
        • Do we process any sensitive personal information?: We do not process sensitive personal information. Click here to learn more.
        • Do we receive any information from third parties?: We do not receive information from third parties. Click here to learn more.
        • How do we process your information?: We process your information to provide, improve, and administer our Services, communicate with you, ensure security and fraud prevention, and comply with the law. We may also process your information for other purposes with your consent. Click here to learn more.
        • In what situations and with which types of parties do we share personal information?: We may share information in specific situations and with certain types of third parties. Click here to learn more.</p>
      • How do we keep your information safe?: We implement organizational and technical measures to protect your personal information. However, no electronic transmission or information storage technology can be guaranteed to be 100% secure. Click here to learn more.
      • What are your rights?: Depending on your geographic location, applicable privacy laws may grant you specific rights regarding your personal information. Click here to learn more.
      • How do you exercise your rights?: You can exercise your rights by filling out our data subject request form available here, or by contacting us directly. We will consider and act upon any requests in accordance with applicable data protection laws. Click here to learn more.
      <p>Want to learn more about what DOM FOOD LLC does with the information we collect? Click here to review the full privacy notice.</p>
      <h3 className="font-bold text-xl mt-4">TABLE OF CONTENTS</h3>
<p>1. WHAT INFORMATION DO WE COLLECT?
  2. HOW DO WE PROCESS YOUR INFORMATION?
  3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
  4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
  5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
  6. HOW LONG DO WE KEEP YOUR INFORMATION?
  7. HOW DO WE KEEP YOUR INFORMATION SAFE?
  8. WHAT ARE YOUR PRIVACY RIGHTS?
  9. CONTROLS FOR DO-NOT-TRACK FEATURES
  10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
  11. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
  12. DO WE MAKE UPDATES TO THIS NOTICE?
  13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
  14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</p>
      <p className="text-xl font-bold mt-4">1. WHAT INFORMATION DO WE COLLECT?</p>
      <p><br/></p>

      <h5>1.1 Personal Information You Disclose to Us</h5>
      <p><br/></p>
      <p><strong>In Short:</strong> We collect personal information that you voluntarily provide to us.</p>
      <p><br/></p>
      <h6>1.1.1 Personal Information Provided by You:</h6>
      <p>When you register on the Services, express interest in obtaining information about us or our products and Services, participate in activities, or otherwise contact us, we collect personal information based on your interactions. This may include:</p>
      <ul>
        <li>Phone numbers</li>
        <li>Email addresses</li>
        <li>Names</li>
      </ul>
      <p><br/></p>
      <h6>1.1.2 Sensitive Information:</h6>
      <p>We do not process sensitive personal information, such as data related to racial or ethnic origin, political opinions, religious or philosophical beliefs, trade union membership, health, or sexual orientation.</p>
      <p><br/></p>
      <h6>1.1.3 Application Data:</h6>
      <p>If you use our application(s), we may collect additional information, including:</p>
      <ul>
        <li><strong>Geolocation Information:</strong> We may request access to track your location, either continuously or while using the app, to provide location-based services. You can change these settings in your device’s settings.</li>
        <li><strong>Push Notifications:</strong> We may send push notifications regarding your account or app features. You can opt-out of these notifications through your device’s settings.</li>
      </ul>
      <p><br/></p>
      <h5>1.2 Information Automatically Collected</h5>
      <p><br/></p>
      <p><strong>In Short:</strong> Some information is collected automatically when you visit or use our Services.</p>
      <p><br/></p>
      <h6>1.2.1 Automatic Data Collection:</h6>
      <p>We automatically collect information such as:</p>
      <ul>
        <li><strong>Log and Usage Data:</strong> Includes diagnostic, usage, and performance information, such as IP address, browser type, device information, and activity logs.</li>
        <li><strong>Device Data:</strong> Includes data about your device, such as IP address, device identifiers, location, and hardware details.</li>
        <li><strong>Location Data:</strong> Includes information about your device’s location, which can be precise or imprecise depending on settings.</li>
      </ul>
      <p><br/></p>
      <h6>1.2.2 Cookies and Tracking Technologies:</h6>
      <p>We use cookies and similar technologies to collect data and enhance your experience. This includes:</p>
      <ul>
        <li><strong>Cookies:</strong> Small data files placed on your device.</li>
        <li><strong>Web Beacons:</strong> Electronic images that track user activity.</li>
      </ul>
      <p><br/></p>
      <h5>1.3 Data Retention</h5>
      <p><br/></p>
      <p>We retain your personal information only as long as necessary to fulfill the purposes for which it was collected, including compliance with legal obligations and reporting requirements.</p>
      <p><br/></p>
      <h5>1.4 Data Security Measures</h5>
      <p><br/></p>
      <p>We implement appropriate technical and organizational measures to protect your personal information against unauthorized access, disclosure, alteration, or destruction.</p>
      <p><br/></p>
      <h5>1.5 User Rights</h5>
      <p><br/></p>
      <p>Depending on your location, you may have the right to access, correct, delete, or restrict the processing of your personal data. To exercise these rights, please contact us using the email address provided in the Contact Information section.</p>
      <p><br/></p>
      <h5>1.6 Third-Party Services</h5>
      <p><br/></p>
      <p>We may share your data with third-party service providers necessary to provide our Services. These third parties must adhere to our data protection standards and practices.</p>
      <p><br/></p>
      <h5>1.7 International Data Transfers</h5>
      <p><br/></p>
      <p>If your data is transferred outside of the Republic of Armenia, we ensure that appropriate safeguards are in place to protect your data in accordance with applicable data protection laws.</p>
      <p><br/></p>

      <p className="text-xl font-bold mt-4">2. HOW DO WE PROCESS YOUR INFORMATION?</p>
      <p><br/></p>

      <p><strong>In Short:</strong> We process your information to provide, improve, and administer our Services, communicate with you, ensure security and prevent fraud, and comply with legal obligations. We may also process your information for additional purposes with your consent.</p>
      <p><br/></p>
      <h5>2.1 To Facilitate Account Creation and Authentication:</h5>
      <p>We process your information to create and manage your account, enable you to log in, and ensure your account remains in good standing. This includes handling account-related requests, such as password resets and updates to your profile information, and maintaining accurate user records.</p>
      <p><br/></p>
      <h5>2.2 To Deliver and Facilitate Delivery of Services:</h5>
      <p>We use your information to provide the services you request, including processing orders, managing deliveries, and facilitating interactions between you and service providers. This ensures that the services you use are delivered efficiently and as requested.</p>
      <p><br/></p>
      <h5>2.3 To Enable User-to-User Communications:</h5>
      <p>If you choose to use features that allow communication with other users, such as messaging or commenting, we process your information to facilitate these interactions and ensure effective communication within our platform.</p>
      <p><br/></p>
      <h5>2.4 To Request Feedback:</h5>
      <p>We may process your information to solicit feedback about our Services and contact you regarding your experience and suggestions for improvement. This helps us enhance our Services and address any issues or concerns you might have.</p>
      <p><br/></p>
      <h5>2.5 To Ensure Security and Prevent Fraud:</h5>
      <p>We process your information to protect our Services from unauthorized access, ensure the integrity of user accounts, and prevent fraudulent activities. This includes monitoring for suspicious activities and implementing security measures to safeguard your information.</p>
      <p><br/></p>
      <h5>2.6 To Comply with Legal Obligations:</h5>
      <p>We process your information to comply with applicable laws, regulations, and legal requests. This ensures that we meet our legal obligations and respond to legal inquiries or requirements.</p>
      <p><br/></p>
      <h5>2.7 To Improve Our Services:</h5>
      <p>We use your information to analyze usage patterns, identify areas for improvement, and enhance the overall user experience of our Services. This includes conducting research and development to refine our offerings.</p>
      <p><br/></p>
      <h5>2.8 With Your Consent:</h5>
      <p>We may process your information for purposes not listed above if you provide us with explicit consent. You have the right to withdraw consent at any time, which will not affect the lawfulness of processing based on consent before its withdrawal.</p>
      <p><br/></p>
      <h5>2.9 Purpose Limitation:</h5>
      <p>We process personal data only for the purposes for which it was collected and in accordance with applicable laws and regulations. We do not use your data for unrelated purposes.</p>
      <p><br/></p>
      <h5>2.10 Data Retention:</h5>
      <p>We retain your personal information only for as long as necessary to fulfill the purposes for which it was collected, unless a longer retention period is required or permitted by law. This ensures that we do not keep your information longer than needed.</p>
      <p><br/></p>
      <h5>2.11 Data Sharing:</h5>
      <p>We may share your information with trusted third parties, such as service providers or partners, to assist in delivering our Services. We do not sell your data to third parties. We may also disclose your information to comply with legal obligations or in response to lawful requests from authorities.</p>
      <p><br/></p>
      <h5>2.12 User Rights and Choices:</h5>
      <p>You have the right to access, correct, or delete your personal data. You can exercise these rights by contacting us using the information provided in the Contact Information section. Additionally, you may have the right to object to or restrict certain types of processing based on applicable laws.</p>
      <p><br/></p>
      <h5>2.13 Automated Decision-Making:</h5>
      <p>We do not use automated decision-making processes that significantly affect you, such as profiling, unless required by law or with your explicit consent. Any automated decisions made will be disclosed to you, and you have the right to challenge such decisions.</p>
      <p><br/></p>
      <h5>2.14 Safeguarding Your Information:</h5>
      <p>We implement appropriate technical and organizational measures to protect your personal information against unauthorized access, disclosure, alteration, or destruction. We continuously review and enhance our security practices to safeguard your data.</p>
      <p><br/></p>
      <h5>2.15 Questions and Contact:</h5>
      <p>If you have any questions about how we process your information or wish to exercise your rights regarding your personal data, please contact us using the information provided in the Contact Information section.</p>
      <p><br/></p>

      <p className="text-xl font-bold mt-4">3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</p>
      <p><br/></p>

      <p><strong>In Short:</strong> We may share your information in specific situations described in this section and/or with the following categories of third parties.</p>
      <p><br/></p>
      <h5>3.1 Vendors, Consultants, and Other Third-Party Service Providers:</h5>
      <p>We may share your data with third-party vendors, service providers, contractors, or agents ("third parties") who perform services for us or on our behalf and require access to such information to do their work. The categories of third parties with whom we may share personal information include:</p>
      <ul>
        <li><strong>Data Analytics Services:</strong> To help us analyze how users interact with our Services and to improve our offerings.</li>
        <li><strong>Payment Processors:</strong> To process transactions and manage billing information.</li>
        <li><strong>Customer Support Providers:</strong> To assist with handling support requests and inquiries.</li>
      </ul>
      <p><br/></p>
      <h5>3.2 Business Transfers:</h5>
      <p>In connection with, or during negotiations for, any merger, sale of company assets, financing, or acquisition of all or a portion of our business by another company, we may share or transfer your information. In such cases, the acquiring entity will handle your information in accordance with their privacy policy. We will notify you of any such transfer and its implications.</p>
      <p><br/></p>
      <h5>3.3 Google Maps Platform APIs:</h5>
      <p>To provide location-based services, we may share your information with Google Maps Platform APIs (e.g., Google Maps API, Places API). For details on how Google handles your data, please refer to Google’s <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">Privacy Policy</a>.</p>
      <p><br/></p>
      <h5>3.4 Other Users:</h5>
      <p>When you share personal information (such as posting comments, contributions, or other content) or interact with public areas of our Services, this information may be visible to all users and may be accessible outside the Services indefinitely. If you register for our Services through a social network (e.g., Facebook), your contacts on that network may see your name, profile photo, and activity descriptions. Similarly, other users will be able to view your activity, communicate with you, and see your profile.</p>
      <p><br/></p>
      <h5>3.5 Legal Requirements and Protection:</h5>
      <p>We may disclose your information if required by law or in response to valid requests from public authorities (e.g., subpoenas or similar legal processes). We may also disclose your information to:</p>
      <ul>
        <li><strong>Enforce our Terms and Conditions:</strong> To ensure compliance and address any violations.</li>
        <li><strong>Protect the Security of our Services:</strong> To prevent and address security threats or breaches.</li>
        <li><strong>Protect the Rights, Property, or Safety:</strong> To safeguard the rights, property, or safety of DOM FOOD LLC, our users, or others.</li>
      </ul>
      <p><br/></p>
      <h5>3.6 Aggregated and De-Identified Information:</h5>
      <p>We may share aggregated or de-identified information that does not directly identify you with third parties for purposes such as research, analysis, and marketing. This information is used in a way that maintains your privacy and confidentiality.</p>
      <p><br/></p>
      <h5>3.7 Third-Party Analytics:</h5>
      <p>We may use third-party analytics services to help us understand usage patterns and improve our Services. These services may collect information about your interactions with the Services.</p>
      <p><br/></p>
      <h5>3.8 Third-Party Advertising:</h5>
      <p>We may work with third-party advertisers to deliver targeted ads based on your interests. These advertisers may use cookies or similar technologies to collect information about your interactions with our Services and other sites.</p>
      <p><br/></p>
      <h5>3.9 Data Security and Compliance:</h5>
      <p>We take appropriate measures to ensure that third parties with whom we share your information provide adequate protection for your data, consistent with applicable data protection laws and regulations.</p>
      <p><br/></p>
      <h5>Questions About Information Sharing:</h5>
      <p>If you have any questions about how your information is shared or require further details, please contact us using the information provided in the Contact Information section.</p>

      <p><br/></p>
      <p className="text-xl font-bold mt-4">4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</p>
      <p><br/></p>

      <p><strong>In Short:</strong> We use cookies and other tracking technologies to collect and store information about your interactions with our Services to enhance your user experience and provide personalized content and advertising.</p>
      <p><br/></p>
      <h5>4.1 Cookies and Tracking Technologies:</h5>
      <p>We use various technologies to collect data about your interactions with our Services. These include:</p>
      <ul>
        <li><strong>Cookies:</strong> Cookies are small data files placed on your device when you visit our website or use our Services. They help us remember your preferences, login information, and other details to enhance your browsing experience. Cookies can be either session cookies, which are deleted when you close your browser, or persistent cookies, which remain on your device until they expire or you delete them.</li>
        <li><strong>Web Beacons and Pixels:</strong> These are small, transparent images embedded in web pages or emails that help us track user interactions with our content and measure the effectiveness of our marketing efforts. Web beacons and pixels are used to collect information such as IP addresses, page views, and time spent on our site.</li>
        <li><strong>Local Storage:</strong> Local storage allows us to store data on your device beyond the current browsing session. This can include storing user preferences or session information to improve the functionality and performance of our Services. Unlike cookies, local storage data is not sent back to the server with each request but is stored directly on your device.</li>
      </ul>
      <p><br/></p>
      <h5>4.2 Purpose of Tracking Technologies:</h5>
      <p>We use cookies and other tracking technologies for various purposes, including:</p>
      <ul>
        <li><strong>Understanding Usage:</strong> To analyze how users interact with our Services, helping us to improve the user experience.</li>
        <li><strong>Personalization:</strong> To provide personalized content and recommendations based on your preferences and usage patterns.</li>
        <li><strong>Advertising:</strong> To deliver relevant advertisements and measure the effectiveness of our marketing campaigns.</li>
      </ul>
      <p><br/></p>
      <h5>4.3 Managing Cookies:</h5>
      <p>You can manage or refuse cookies through your browser settings or other tools. For detailed instructions on how to control and delete cookies, please refer to our <a href="cookie-notice.html" target="_blank">Cookie Notice</a>. Please note that disabling cookies may affect your ability to use certain features of our Services and may impact your overall experience.</p>
      <p><br/></p>
      <h5>4.4 Third-Party Cookies:</h5>
      <p>We may also use cookies and tracking technologies provided by third parties, such as analytics and advertising partners. These third parties have their own privacy policies and may collect and use data according to their practices.</p>
      <p><br/></p>
      <h5>4.5 Consent and Changes:</h5>
      <p>By continuing to use our Services, you consent to the use of cookies and other tracking technologies as described in this notice. We may update our use of these technologies and will notify you of any significant changes through our <a href="cookie-notice.html" target="_blank">Cookie Notice</a> or other communication channels.</p>
      <p><br/></p>
      <p>For more information about the cookies and tracking technologies we use, and to manage your preferences, please review our <a href="cookie-notice.html" target="_blank">Cookie Notice</a>.</p>
      <p><br/></p>
      <p className="text-xl font-bold mt-4">5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</p>
      <p><br/></p>

      <p><strong>In Short:</strong> If you choose to register or log in to our Services using a social media account, we may have access to certain information about you.</p>
      <p><br/></p>
      <h5>5.1 Use of Social Media Logins:</h5>
      <p>Our Services provide the option to register and log in using third-party social media accounts (such as Facebook, Twitter, or other social media platforms). When you opt to use this feature, we may receive certain profile information from your social media provider. The specific information we receive can vary depending on the provider but generally includes:</p>
      <ul>
        <li>Name</li>
        <li>Email address</li>
        <li>Friends list</li>
        <li>Profile picture</li>
        <li>Other information you have chosen to make public on the social media platform</li>
      </ul>
      <p><br/></p>
      <h5>5.2 Purpose of Data Collection:</h5>
      <p>We use the information received from your social media accounts for the following purposes:</p>
      <ul>
        <li><strong>Facilitating Account Creation:</strong> To streamline your registration process and set up your account with us.</li>
        <li><strong>Personalizing Your Experience:</strong> To tailor the Services to your preferences and improve your overall experience.</li>
        <li><strong>Improving Our Services:</strong> To enhance and optimize the features and functionality of our Services.</li>
      </ul>
      <p><br/></p>
      <h5>5.3 Control and Responsibility:</h5>
      <p>Please be aware that while we use the information from your social media account to enhance your experience with our Services, we do not control how your social media provider uses your personal information. We are not responsible for their practices. We encourage you to review the privacy notice of your social media provider to understand their data collection, use, and sharing practices. You can also adjust your privacy settings on their platforms to manage the information they share with us.</p>
      <p><br/></p>
      <h5>5.4 Security and Data Handling:</h5>
      <p>We handle the information we receive from social media accounts in accordance with this privacy notice. We take appropriate measures to protect your data from unauthorized access and misuse.</p>
      <p><br/></p>
      <h5>5.5 Concerns and Contact:</h5>
      <p>If you have any concerns about the information we receive from your social media accounts or how it is used, please contact us using the information provided in the <a href="contact-information.html">Contact Information</a> section. We are committed to addressing any issues you may have regarding your data.</p>
      <p><br/></p>
      <h5>5.6 Data Retention:</h5>
      <p>We retain the information received from social media accounts for as long as necessary to fulfill the purposes described in this privacy notice, unless otherwise required by law.</p>
      <p><br/></p>
      <p className="text-xl font-bold mt-4">6. HOW LONG DO WE KEEP YOUR INFORMATION?</p>
      <p><br/></p>

      <p><strong>In Short:</strong> We retain your personal information only as long as necessary to fulfill the purposes described in this privacy notice, unless otherwise required by law.</p>
      <p><br/></p>
      <h5>6.1 Retention Period:</h5>
      <p>We will keep your personal information for as long as necessary to fulfill the purposes outlined in this privacy notice. This includes:</p>
      <ul>
        <li><strong>Account Duration:</strong> For the duration of your account with us.</li>
        <li><strong>Legal Requirements:</strong> As required by applicable laws, such as tax, accounting, or other regulatory requirements.</li>
      </ul>
      <p><br/></p>
      <h5>6.2 When Information is No Longer Needed:</h5>
      <p>If there is no ongoing legitimate business need to process your personal information, we will take the following actions:</p>
      <ul>
        <li><strong>Deletion or Anonymization:</strong> We will either delete or anonymize your information. Deletion means permanently removing the data from our systems, while anonymization involves removing personally identifiable information so that the data cannot be linked back to you.</li>
        <li><strong>Secure Storage:</strong> If deletion or anonymization is not possible (e.g., data stored in backup archives), we will securely store your personal information and isolate it from further processing until it can be properly deleted or anonymized.</li>
      </ul>
      <p><br/></p>
      <h5>6.3 Retention Limits:</h5>
      <p>The retention period for your personal information will not exceed the time necessary to achieve the purposes for which it was collected or as required by law. We regularly review our data retention practices to ensure compliance with legal requirements and operational needs.</p>
      <p><br/></p>
      <h5>6.4 Data Minimization:</h5>
      <p>We aim to adhere to the principle of data minimization by keeping your personal information only for as long as necessary and ensuring it is handled responsibly.</p>
      <p><br/></p>
      <h5>6.5 Reviewing and Updating:</h5>
      <p>We may periodically review and update our data retention policies to reflect changes in legal requirements, business practices, or technological advancements.</p>
      <p><br/></p>
      <h5>6.6 For More Information:</h5>
      <p>For further details on how we handle your data and our data retention practices, please refer to our <a href="privacy-policy.html">Privacy Policy</a> or contact us using the information provided in the <a href="contact-information.html">Contact Information</a> section.</p>
      <p><br/></p>

      <p className="text-xl font-bold mt-4">7. HOW DO WE KEEP YOUR INFORMATION SAFE?</p>
      <p><br/></p>

      <p><strong>In Short:</strong> We aim to protect your personal information through a comprehensive system of organizational and technical security measures.</p>
      <p><br/></p>
      <h5>7.1 Security Measures:</h5>
      <p>We have implemented appropriate and reasonable technical and organizational security measures to safeguard your personal information. These measures include:</p>
      <ul>
        <li><strong>Encryption:</strong> We use encryption to protect data during transmission and storage, ensuring that your information is secure from unauthorized access.</li>
        <li><strong>Firewalls:</strong> Our systems are protected by firewalls to prevent unauthorized access to our networks and systems.</li>
        <li><strong>Access Controls:</strong> We restrict access to personal information to authorized personnel only, using secure authentication methods and role-based access controls.</li>
        <li><strong>Regular Security Assessments:</strong> We conduct regular security assessments, including vulnerability scans and penetration tests, to identify and address potential security weaknesses.</li>
      </ul>
      <p><br/></p>
      <h5>7.2 No Absolute Guarantee of Security:</h5>
      <p>Despite our extensive security measures and efforts to protect your information, no electronic transmission or storage technology is 100% secure. As such, we cannot guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to breach our security measures and gain access to your personal information.</p>
      <p><br/></p>
      <h5>7.3 User Responsibility:</h5>
      <p>The transmission of personal information to and from our Services is at your own risk. We recommend accessing our Services within a secure environment and taking necessary precautions to protect your personal information. This includes using secure passwords, keeping your devices protected, and being cautious of phishing attempts and other security threats.</p>
      <p><br/></p>
      <h5>7.4 Security Incidents:</h5>
      <p>If you have any concerns about the security of your personal information or believe that a security breach has occurred, please contact us immediately using the information provided in the <a href="contact-information.html">Contact Information</a> section. We will investigate any reported incidents and take appropriate actions to address and resolve them.</p>
      <p><br/></p>
      <h5>7.5 Continuous Improvement:</h5>
      <p>We are committed to maintaining the security of your personal information and continuously improving our security protocols. We regularly review and update our security practices to keep pace with emerging threats and technological advancements.</p>
      <p><br/></p>
      <h5>7.6 Reporting Security Issues:</h5>
      <p>If you encounter any security issues or suspect that your personal information may have been compromised, please report it to us as soon as possible. Prompt reporting helps us address and mitigate potential risks effectively.</p>
      <p><br/></p>
      <p>For more information on our security practices and how we protect your personal information, please refer to our <a href="privacy-policy.html">Privacy Policy</a> or contact us using the information provided in the <a href="contact-information.html">Contact Information</a> section.</p>
      <p><br/></p>
      <p className="text-xl font-bold mt-4">8. WHAT ARE YOUR PRIVACY RIGHTS?</p>
      <p><br/></p>
      <p><strong>In Short:</strong> You have the right to review, change, or terminate your account at any time. Additionally, depending on your location, you have specific rights regarding your personal information.</p>
      <p><br/></p>
      <h5>8.1 Rights in the EEA and UK:</h5>
      <p>If you are located in the European Economic Area (EEA) or the UK and believe that we are unlawfully processing your personal information, you have the right to:</p>
      <ul>
        <li><strong>File a Complaint:</strong> Submit a complaint to your local data protection supervisory authority. You can find their contact details <a href="https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm">here</a>.</li>
      </ul>
      <p><br/></p>
      <h5>8.2 Rights in Switzerland:</h5>
      <p>If you are located in Switzerland, you can contact the Swiss Federal Data Protection and Information Commissioner for any concerns about our processing of your personal data. Their contact details are available <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">here</a>.</p>
      <p><br/></p>
      <h5>8.3 Withdrawing Your Consent:</h5>
      <p>If our processing of your personal information is based on your consent (whether express or implied), you have the right to withdraw that consent at any time. To withdraw consent, contact us using the details provided in the section <a href="#contact-us">"HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"</a> below. Please note:</p>
      <ul>
        <li><strong>Effect on Lawfulness:</strong> Withdrawing consent does not affect the lawfulness of processing conducted before the withdrawal.</li>
        <li><strong>Processing on Other Grounds:</strong> If applicable law allows, processing based on other lawful grounds (e.g., contractual necessity, legal obligations) will not be affected.</li>
      </ul>
      <p><br/></p>
      <h5>8.4 Opting Out of Marketing and Promotional Communications:</h5>
      <p>You can opt out of receiving marketing and promotional communications at any time:</p>
      <ul>
        <li><strong>Unsubscribe:</strong> Click the unsubscribe link in our marketing emails.</li>
        <li><strong>Contact Us:</strong> Alternatively, you can contact us using the details provided in the section <a href="#contact-us">"HOW CAN YOU CONTACT US ABOUT THIS NOTICE?"</a> below.</li>
        <li><strong>Service-Related Communications:</strong> Please note that you may still receive service-related communications necessary for the administration of your account, responding to service requests, or for other non-marketing purposes.</li>
      </ul>
      <p><br/></p>
      <h5>8.5 Account Information:</h5>
      <p>To review, update, or terminate your account:</p>
      <ul>
        <li><strong>Access Account Settings:</strong> Log in to your account settings and make necessary changes.</li>
        <li><strong>Termination Requests:</strong> Upon request to terminate your account, we will deactivate or delete your account and information from our active databases. Some information may be retained in our files to:</li>
        <ul>
          <li>Prevent Fraud: To prevent fraudulent activities.</li>
          <li>Troubleshoot Problems: To assist with troubleshooting.</li>
          <li>Assist with Investigations: To support investigations.</li>
          <li>Enforce Legal Terms: To enforce our legal terms and conditions.</li>
          <li>Comply with Legal Requirements: To meet legal obligations.</li>
        </ul>
      </ul>
      <p><br/></p>
      <h5>8.6 Cookies and Similar Technologies:</h5>
      <p>Most web browsers accept cookies by default. You can adjust your browser settings to remove or reject cookies:</p>
      <ul>
        <li><strong>Impact of Changes:</strong> Be aware that removing or rejecting cookies may affect certain features or services of our Services.</li>
        <li><strong>Interest-Based Advertising:</strong> To opt out of interest-based advertising by advertisers on our Services, visit <a href="https://www.aboutads.info/">About Ads</a>.</li>
      </ul>
      <p><br/></p>
      <h5>8.7 Contact Us:</h5>
      <p>If you have questions or comments about your privacy rights or how we handle your personal information, please contact us at <a href="mailto:support@domfood.com">support@domfood.com</a>.</p>
      <p><br/></p>
      <p>For more information about your rights and our data handling practices, please review our <a href="privacy-policy.html">Privacy Policy</a>.</p>

      <p><br/></p>

      <p className="text-xl font-bold mt-4">9. CONTROLS FOR DO-NOT-TRACK FEATURES</p>
      <p><br/></p>

      <p><strong>In Short:</strong> Currently, we do not respond to Do-Not-Track (DNT) signals due to the lack of a standardized technology for recognizing these signals. However, you can manage or limit data collection through your browser or device settings.</p>
      <p><br/></p>
      <h5>9.1 What is Do-Not-Track (DNT)?:</h5>
      <p>Do-Not-Track (DNT) is a privacy preference that users can set in their web browsers or mobile operating systems to signal that they do not wish to have their online activities tracked by websites and services.</p>
      <p><br/></p>
      <h5>9.2 Current Limitations:</h5>
      <p>As there is no uniform technology standard for recognizing and implementing DNT signals across all platforms, DOM FOOD LLC does not currently respond to DNT browser signals or other mechanisms that communicate your preference not to be tracked. This means we may still collect and use your data as described in this privacy notice.</p>
      <p><br/></p>
      <h5>9.3 Future Changes:</h5>
      <p>If a standardized approach to online tracking, including DNT signals, becomes established and legally required in the future, DOM FOOD LLC will update this privacy notice to reflect our practices concerning DNT signals and your privacy preferences. We are committed to complying with applicable regulations and will adapt our practices as necessary.</p>
      <p><br/></p>
      <h5>9.4 Managing Your Privacy Settings:</h5>
      <p>While we do not currently respond to DNT signals, you can manage or limit the data collection and tracking of your online activities through the following methods:</p>
      <ul>
        <li><strong>Browser Settings:</strong> Adjust your web browser settings to manage cookies and tracking technologies. Most browsers offer options to block or delete cookies and to limit the data collected.</li>
        <li><strong>Privacy Tools:</strong> Use privacy tools and browser extensions that can help block trackers and enhance your online privacy.</li>
        <li><strong>Device Settings:</strong> Review and adjust the privacy settings on your mobile device or other digital platforms to control how your information is collected and used.</li>
      </ul>
      <p><br/></p>
      <h5>9.5 Reviewing Privacy Settings:</h5>
      <p>We encourage you to regularly review the privacy settings and options available on your browser or device to ensure they align with your privacy preferences.</p>
      <p><br/></p>
      <p>For more information on how to control tracking technologies and manage your privacy settings, please refer to the help resources and privacy options provided by your browser or device manufacturer. If you have any questions about our practices or how we handle your data, please contact us using the information provided in the <a href="#contact-us">Contact Information</a> section.</p>

      <p><br/></p>

      <p className="text-xl font-bold mt-4">10. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</p>
      <p><br/></p>

      <p><strong>In Short:</strong> Yes, if you are a resident of California, you have specific rights regarding access to and control over your personal information under California law.</p>
      <p><br/></p>
      <h5>10.1 Rights Under California Consumer Privacy Act (CCPA):</h5>
      <p>As a California resident, you have the following rights regarding your personal information:</p>
      <ol>
        <li><strong>Request for Information:</strong> Under California Civil Code Section 1798.83, also known as the "Shine The Light" law, you may request and obtain, once a year and free of charge, information about the categories of personal information (if any) that we have disclosed to third parties for direct marketing purposes. Additionally, you may request the names and addresses of all third parties with which we have shared your personal information in the immediately preceding calendar year. To make such a request, please submit it in writing to us using the contact information provided below.</li>
        <li><strong>Rights for Minors:</strong> If you are under 18 years of age, reside in California, and have a registered account with our Services, you have the right to request the removal of unwanted data that you have publicly posted on our Services. To request the removal of such data:
          <ul>
            <li><strong>Contact Us:</strong> Email us using the contact information provided below.</li>
            <li><strong>Include Information:</strong> Provide the email address associated with your account and a statement confirming that you reside in California.</li>
            <li><strong>Data Removal:</strong> We will make reasonable efforts to ensure that the data is not publicly displayed on our Services. However, please be aware that the data may not be completely or comprehensively removed from all our systems, such as backups.</li>
          </ul>
        </li>
      </ol>
      <p><br/></p>
      <h5>10.2 Additional Rights Under CCPA:</h5>
      <p>In addition to the rights mentioned above, the CCPA provides California residents with the following rights:</p>
      <ul>
        <li><strong>Right to Access:</strong> You may request access to the specific pieces of personal information we hold about you.</li>
        <li><strong>Right to Delete:</strong> You may request the deletion of your personal information.</li>
        <li><strong>Right to Opt-Out:</strong> You may opt out of the sale of your personal information, if applicable.</li>
        <li><strong>Right to Non-Discrimination:</strong> You have the right not to be discriminated against for exercising your privacy rights under the CCPA.</li>
      </ul>
      <p><br/></p>
      <h5>10.3 Exercising Your Rights:</h5>
      <p>To exercise any of your privacy rights, please contact us at:</p>
      <address>
        <strong>DOM FOOD LLC</strong><br/>
        34 Chaykovski Street 19<br/>
        Yerevan, Yerevan 0010<br/>
        Republic of Armenia<br/>
        Email: <a href="mailto:info@domfood.com">info@domfood.com</a>
      </address>
      <p><br/></p>
      <h5>10.4 Verification and Response:</h5>
      <p>Upon receiving your request, we may need to verify your identity before processing it. We will respond to your request within the timeframe required by applicable law and inform you of the actions taken in response to your request.</p>
      <p><br/></p>
      <h5>10.5 Changes to This Section:</h5>
      <p>We may update this section to comply with changes in California privacy laws or our practices. Please review this section periodically for any updates.</p>
      <p><br/></p>
      <p>For more detailed information on how we handle your personal information and your privacy rights, please refer to our <a href="#privacy-policy">Privacy Policy</a>.</p>

      <p><br/></p>

      <p className="text-xl font-bold mt-4">11. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</p>
      <p><br/></p>

      <p><strong>In Short:</strong> Yes, if you are a resident of Virginia, you have specific rights regarding access to and use of your personal information under the Virginia Consumer Data Protection Act (CDPA).</p>
      <p><br/></p>
      <h5>11.1 Definition of Key Terms:</h5>
      <ul>
        <li><strong>"Consumer":</strong> A natural person who is a resident of the Commonwealth of Virginia acting solely in an individual or household context. This does not include individuals acting in a commercial or employment context.</li>
        <li><strong>"Personal Data":</strong> Any information that is linked or reasonably linkable to an identified or identifiable natural person. This does not include de-identified data or publicly available information.</li>
        <li><strong>"Sale of Personal Data":</strong> The exchange of personal data for monetary consideration.</li>
      </ul>
      <p><br/></p>
      <h5>11.2 Your Rights Under the Virginia CDPA:</h5>
      <ul>
        <li><strong>Right to Be Informed:</strong> You have the right to know whether we are processing your personal data.</li>
        <li><strong>Right to Access:</strong> You can request access to the personal data we hold about you.</li>
        <li><strong>Right to Correct:</strong> You have the right to correct any inaccuracies in your personal data.</li>
        <li><strong>Right to Deletion:</strong> You can request the deletion of your personal data.</li>
        <li><strong>Right to Obtain a Copy:</strong> You have the right to obtain a copy of the personal data you previously shared with us.</li>
        <li><strong>Right to Opt-Out:</strong> You may opt out of the processing of your personal data if it is used for:
          <ul>
            <li><strong>Targeted Advertising:</strong> Processing for targeted advertising.</li>
            <li><strong>Sale of Personal Data:</strong> The sale of personal data.</li>
            <li><strong>Profiling:</strong> Profiling that results in significant decisions affecting you.</li>
          </ul>
        </li>
      </ul>
      <p><br/></p>
      <h5>11.3 Processing of Personal Data:</h5>
      <ul>
        <li><strong>Sale of Data:</strong> DOM FOOD LLC does not sell personal data to third parties.</li>
        <li><strong>Targeted Advertising and Profiling:</strong> We may process personal data for targeted advertising or profiling. To opt out of such processing, you can disable cookies through your <a href="#cookie-preference-settings">Cookie Preference Settings</a>.</li>
      </ul>
      <p><br/></p>
      <h5>11.4 Exercising Your Rights:</h5>
      <ul>
        <li><strong>Contact Us:</strong> To exercise your rights under the Virginia CDPA, please contact us via:
          <ul>
            <li><strong>Email:</strong> <a href="mailto:support@domfood.com">support@domfood.com</a></li>
            <li><strong>Data Request Form:</strong> <a href="http://dom-food.com/data-request">http://dom-food.com/data-request</a></li>
            <li><strong>Contact Details:</strong> See the contact information at the bottom of this document.</li>
          </ul>
        </li>
        <li><strong>Authorized Agents:</strong> If you are using an authorized agent to exercise your rights, we may require proof that the agent is authorized to act on your behalf.</li>
      </ul>
      <p><br/></p>
      <h5>11.5 Verification Process:</h5>
      <p>We may request additional information to verify your identity and the validity of your request. If submitted through an authorized agent, additional information may be required to verify the agent’s authority.</p>
      <p><br/></p>
      <h5>11.6 Response Time:</h5>
      <ul>
        <li><strong>Initial Response:</strong> We will respond to your request without undue delay, and in all cases, within forty-five (45) days of receipt.</li>
        <li><strong>Extension:</strong> The response period may be extended once by an additional forty-five (45) days if reasonably necessary. We will notify you of any such extension within the initial 45-day period, along with the reason for the extension.</li>
      </ul>
      <p><br/></p>
      <h5>11.7 Right to Appeal:</h5>
      <p>If we decline to take action on your request, we will provide you with our decision and the reasons behind it. If you wish to appeal our decision, please email us at <a href="mailto:support@domfood.com">support@domfood.com</a>. Within sixty (60) days of receiving your appeal, we will inform you in writing of any action taken or not taken in response to the appeal, including a written explanation. If your appeal is denied, you may contact the Attorney General to submit a complaint.</p>
      <p><br/></p>
      <p>For additional information on your rights under the Virginia CDPA and how we handle your personal information, please refer to our <a href="#privacy-policy">Privacy Policy</a> or contact us using the information provided in the <a href="#contact-information">Contact Information</a> section.</p>

      <p><br/></p>

      <p className="text-xl font-bold mt-4">12. DO WE MAKE UPDATES TO THIS NOTICE?</p>
      <p><br/></p>

      <p><strong>In Short:</strong> Yes, we may update this privacy notice as necessary to comply with relevant laws and to reflect changes in our practices.</p>
      <p><br/></p>
      <h5>12.1 Updates to the Privacy Notice:</h5>
      <p>We may revise this privacy notice periodically to reflect changes in our practices, technology, or legal requirements. The updated version will be indicated by a revised "Effective Date" at the top of this notice. The changes will become effective as soon as the updated notice is posted on our Services.</p>
      <p><br/></p>
      <h5>12.2 Notification of Changes:</h5>
      <ul>
        <li><strong>Posting on Services:</strong> We will prominently post a notice of any material changes to this privacy notice on our Services, such as our website and mobile application.</li>
        <li><strong>Direct Notification:</strong> If you have provided us with your contact information, we may also send you a direct notification, such as an email, to inform you of significant changes.</li>
      </ul>
      <p><br/></p>
      <h5>12.3 Review of Privacy Notice:</h5>
      <p>We encourage you to review this privacy notice regularly to stay informed about how we are protecting your information and to understand any changes to our practices. Your continued use of our Services after the posting of updates constitutes your acceptance of the revised privacy notice.</p>
      <p><br/></p>
      <h5>12.4 Contact Us:</h5>
      <p>If you have any questions or concerns about updates to this privacy notice or our data protection practices, please contact us using the information provided in the <a href="#contact-information">Contact Information</a> section.</p>
      <p><br/></p>
      <p>For more detailed information on our data handling practices and how we update this notice, please review our <a href="#privacy-policy">Privacy Policy</a>.</p>
      <p><br/></p>

      <p className="text-xl font-bold mt-4">13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</p>
      <p><br/></p>

      <p>If you have any questions, comments, or concerns about this privacy notice or our data protection practices, please contact us using the following methods:</p>
      <p><br/></p>
      <ul>
        <li><strong>Email:</strong> <a href="mailto:support@domfood.com">support@domfood.com</a> (For general inquiries and support related to our privacy practices.)</li>
        <li><strong>Postal Mail:</strong>
          <ul>
            <li>DOM FOOD LLC</li>
            <li>34 Chaykovski Street 19</li>
            <li>Yerevan, Yerevan 0010</li>
            <li>Republic of Armenia</li>
          </ul>
          <p><strong>Email:</strong> <a href="mailto:info@domfood.com">info@domfood.com</a></p>
        </li>
      </ul>
      <p><br/></p>
      <h5>Response Time:</h5>
      <p>We aim to respond to your inquiries and concerns as promptly as possible. If your inquiry requires additional time, we will inform you of the estimated response time.</p>
      <p><br/></p>
      <h5>Specific Questions:</h5>
      <p>If you have specific questions or require further clarification regarding our privacy practices, please do not hesitate to reach out to us. We are committed to addressing your concerns and ensuring that your personal information is handled in accordance with this privacy notice.</p>
      <p><br/></p>
      <h5>Privacy Officer:</h5>
      <p>For privacy-related issues, you may request to speak with our Privacy Officer if applicable. They are responsible for overseeing our data protection practices and ensuring compliance with privacy regulations.</p>
      <p><br/></p>
      <h5>Additional Resources:</h5>
      <p>You can also refer to our <a href="#privacy-policy">Privacy Policy</a> for more detailed information about how we manage your personal data.</p>
      <p><br/></p>

      <p className="text-xl font-bold mt-4">14. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</p>
      <p><br/></p>

      <p>You have the right to request access to, update, or delete the personal information we collect from you. To exercise these rights, please follow these steps:</p>
      <p><br/></p>
      <h5>14.1 Reviewing Your Data:</h5>
      <ul>
        <li><strong>Request Access:</strong> To request access to the personal information we hold about you, visit <a href="http://dom-food.com/data-request">http://dom-food.com/data-request</a>. You can review the data we have collected and understand how it is being used. This includes details about the types of personal information collected, its sources, and its purpose.</li>
      </ul>
      <p><br/></p>
      <h5>14.2 Updating Your Data:</h5>
      <ul>
        <li><strong>Submit Updates:</strong> If you need to update or correct any inaccuracies in your personal information, submit a request through the same link provided above. Ensure that you provide the updated information and specify the changes you wish to make. This will help us maintain accurate and up-to-date records.</li>
      </ul>
      <p><br/></p>
      <h5>14.3 Deleting Your Data:</h5>
      <ul>
        <li><strong>Request Deletion:</strong> To request the deletion of your personal information, use <a href="http://dom-food.com/data-request">http://dom-food.com/data-request</a>. We will process your request and ensure that your information is removed from our active databases. Please be aware that some information may be retained for legal, security, or operational purposes, such as:
          <ul>
            <li>Fraud Prevention: To prevent fraudulent activities.</li>
            <li>Legal Compliance: To comply with legal obligations or regulations.</li>
            <li>Operational Necessity: To support ongoing business operations or audits.</li>
          </ul>
        </li>
      </ul>
      <p><br/></p>
      <h5>14.4 Processing Requests:</h5>
      <ul>
        <li><strong>Response Time:</strong> Upon receiving your request, we will respond without undue delay and within the timeframes required by applicable data protection laws. We will notify you once your request has been processed and inform you of any actions taken.</li>
      </ul>
      <p><br/></p>
      <h5>14.5 Authorized Agents:</h5>
      <ul>
        <li><strong>Proof of Authorization:</strong> If you are using an authorized agent to make these requests on your behalf, we may require proof of authorization before processing the request. This ensures that requests are legitimate and made by individuals with proper authorization.</li>
      </ul>
      <p><br/></p>
      <h5>14.6 Additional Assistance:</h5>
      <ul>
        <li><strong>Contact Us:</strong> For any further questions or if you need assistance with your data requests, please contact us at <a href="mailto:support@domfood.com">support@domfood.com</a>. Our team is available to help you with any issues related to accessing, updating, or deleting your personal information.</li>
      </ul>
      <p><br/></p>
      <p>For more information on how we handle your data and your rights, please refer to our <a href="#privacy-policy">Privacy Policy</a>.</p>
      <p><br/></p>
    </div>
  )
}

export default PrivacyPolicy;
