import BlockOneImage from '../images/blockOne.png'
import MapImage from '../images/mapImage.png'
import Call from "./Call";
import Animation from "./Animation";

const gridItemStyle = {
  backgroundImage: `url(${BlockOneImage})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  mixBlendMode: 'soft-light',
  position: 'relative'
}

const BlockOne = () => {
  return (
    <div className="w-full h-[79%] mt-20" style={gridItemStyle}>
      <div className="flex items-center pl-[165px]">
        <div className="text-start">
          <h1 className="text-6xl text-[#DE4333] font-custom font-bold mt-[14px]">DomFood</h1>
          <h2 className="text-3xl text-[#333F51] text-nowrap font-bold font-custom mt-4">Домашняя кухня, доставленная прямо
            </h2>
          <h2 className="text-3xl text-[#333F51] text-nowrap font-bold font-custom mt-2">к вашему дверному порогу!</h2>
          <div className="mt-10">
            <p className="text-[#404F65] text-xl font-normal text-nowrap mt-[10px]">Скачайте уникальное приложение Domfood прямо сейчас и</p>
            <p className="text-[#404F65] font-normal text-xl">откройте для себя новый уровень комфорта и</p>
            <p className="text-[#404F65] text-xl">гастрономического наслаждения!</p>
          </div>
          <button className="mt-28	rounded-[30px] h-[52px] bg-gradient-to-r from-[#DE4333] to-[#FDA110] text-white px-10 font-bold">Оставить заявку</button>
        </div>
        <div className="min-w-[500px]">
          <img src={MapImage} alt="png" />
        </div>
        <Animation />
      </div>
      <Call />
    </div>
  )
}

export default BlockOne